// Generated by Framer (b9ee990)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const cycleOrder = ["rmG12R6yv"];

const variantClassNames = {rmG12R6yv: "framer-v-abswps"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; title?: string }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "rmG12R6yv", title: ZCsLXUICt = "Tag", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "rmG12R6yv", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-6vN8y", classNames)} style={{display: "contents"}}>
<motion.div {...restProps} className={cx("framer-abswps", className)} data-border data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"rmG12R6yv"} ref={ref} style={{"--border-bottom-width": "1px", "--border-color": "rgb(216, 223, 242)", "--border-left-width": "1px", "--border-right-width": "1px", "--border-style": "solid", "--border-top-width": "1px", backgroundColor: "rgb(245, 247, 255)", borderBottomLeftRadius: 4, borderBottomRightRadius: 4, borderTopLeftRadius: 4, borderTopRightRadius: 4, ...style}} transition={transition}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--framer-font-size": "12px", "--framer-line-height": "16px", "--framer-text-color": "var(--extracted-r6o4lv)"}}>QA Engineer</motion.p></React.Fragment>} className={"framer-132uyoq"} data-framer-name={"Tag"} layoutDependency={layoutDependency} layoutId={"eXc81SRcC"} style={{"--extracted-r6o4lv": "rgb(26, 29, 35)", "--framer-paragraph-spacing": "0px"}} text={ZCsLXUICt} transition={transition} verticalAlignment={"top"} withExternalLayout/></motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-6vN8y [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-6vN8y .framer-1qv732y { display: block; }", ".framer-6vN8y .framer-abswps { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 4px; height: min-content; justify-content: center; overflow: hidden; padding: 4px 8px 4px 8px; position: relative; width: min-content; will-change: transform; }", ".framer-6vN8y .framer-132uyoq { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-6vN8y .framer-abswps { gap: 0px; } .framer-6vN8y .framer-abswps > * { margin: 0px; margin-left: calc(4px / 2); margin-right: calc(4px / 2); } .framer-6vN8y .framer-abswps > :first-child { margin-left: 0px; } .framer-6vN8y .framer-abswps > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 24
 * @framerIntrinsicWidth 37
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 * @framerVariables {"ZCsLXUICt":"title"}
 */
const FramerfWEeAFXEy: React.ComponentType<Props> = withCSS(Component, css, "framer-6vN8y") as typeof Component;
export default FramerfWEeAFXEy;

FramerfWEeAFXEy.displayName = "Tag Copy 2";

FramerfWEeAFXEy.defaultProps = {height: 24, width: 37};

addPropertyControls(FramerfWEeAFXEy, {ZCsLXUICt: {defaultValue: "Tag", displayTextArea: false, title: "Title", type: ControlType.String}} as any)

addFonts(FramerfWEeAFXEy, [])